import { I18nOptions } from 'vue-i18n'
import { getStaticUserData } from '@/api/utils/user'
import { NumberFormat } from '@/modules/base/utils/numberFormat.types'
import { DateFormat } from '../utils/dateFormat'

type NumberFormats = NonNullable<I18nOptions['numberFormats']>[string]

const defaultNumberFormats: NumberFormats = {
  [NumberFormat.Compact]: {
    notation: 'compact',
    maximumSignificantDigits: 3,
    useGrouping: true,
  },
  [NumberFormat.Currency]: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  [NumberFormat.CurrencyShort]: {
    maximumFractionDigits: 0,
  },
  [NumberFormat.EmissionFactor]: {
    maximumFractionDigits: 3,
  },
  [NumberFormat.Integer]: {
    maximumFractionDigits: 0,
  },
  [NumberFormat.Percentage]: {
    maximumFractionDigits: 2,
  },
  [NumberFormat.PhysicalQuantity]: {
    maximumFractionDigits: 2,
  },
  [NumberFormat.YearsFractional]: {
    maximumFractionDigits: 2,
  },
  [NumberFormat.InputCurrency]: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  },
  [NumberFormat.InputCurrencyExchangeRate]: {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
    useGrouping: false,
  },
  [NumberFormat.InputEmissionFactor]: {
    maximumFractionDigits: 5,
    useGrouping: false,
  },
  [NumberFormat.InputPercentage]: {
    maximumFractionDigits: 9,
    useGrouping: false,
  },
  [NumberFormat.InputPhysicalQuantity]: {
    maximumFractionDigits: 3,
    useGrouping: false,
  },
  [NumberFormat.InputYearsFractional]: {
    maximumFractionDigits: 2,
    useGrouping: false,
  },
}

export const numberFormats: I18nOptions['numberFormats'] = {
  'en-DE': defaultNumberFormats,
  'de-DE': defaultNumberFormats,
}

type DatetimeFormats = NonNullable<I18nOptions['datetimeFormats']>[string]

const defaultDatetimeFormats: DatetimeFormats = {
  [DateFormat.YearTwoDigit]: {
    year: '2-digit',
  },
  [DateFormat.Date]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  [DateFormat.DateWithTime]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
}

export const datetimeFormats: I18nOptions['datetimeFormats'] = {
  'en-DE': defaultDatetimeFormats,
  'de-DE': defaultDatetimeFormats,
}

export const getI18nOptions = (): I18nOptions => ({
  legacy: false,
  locale: getStaticUserData().languageCode,
  fallbackLocale: 'en-DE',
  globalInjection: true,
  silentTranslationWarn: true,
  numberFormats,
  datetimeFormats,
})
