import VerEx from 'verbal-expressions'
import { Ref, ref, unref } from 'vue'
import { createI18n, I18n } from 'vue-i18n'
import { Language } from '@/api/useLanguages.types'
import { getI18nOptions } from '../config/i18nConfig'
import { appsignal } from './useAppsignal'

const localeFiles = import.meta.glob('@/modules/*/locales/*.json')

type I18nType = I18n<
  Record<string, Record<string, unknown>>,
  Record<string, Record<string, unknown>>,
  Record<string, Record<string, unknown>>
>

export const i18n = ref<I18nType>()

export const setupI18n = (): I18nType => {
  i18n.value = createI18n(getI18nOptions())
  return i18n.value
}

/**
 * Use it to change I18n messages
 */
export const useI18nManager = () => {
  // TODO potentially make it load automatically on route change

  const getLanguage = (locale: string): Language => {
    if (!locale) {
      return Language.German
    }

    const language = locale.substring(0, 2)
    if (language !== Language.English && language !== Language.German) {
      throw new Error(`Unsupported locale '${locale}'!`)
    }

    return language as Language
  }

  const loadJson = (locale: string, moduleName: string) => {
    const regex = VerEx()
      .anything()
      .find(`/${moduleName}/`)
      .anything()
      .then(`/${getLanguage(locale)}.json`)
      .toRegExp()

    for (const path in localeFiles) {
      if (regex.test(path)) {
        return localeFiles[path]()
      }
    }

    throw new Error(`Can not find '${moduleName}' for '${locale}'!`)
  }

  const isModuleAlreadyLoaded = (
    messages: Record<string, Record<string, unknown>>,
    locale: string,
    moduleName: string
  ) => {
    return moduleName in messages[locale]
  }

  /**
   * load new locale based on locale id
   * @description use it in views or App.vue. You'll need to block rendering of the view until locale is loaded
   * @param moduleName name of the part of locale json You want to import
   * @returns isLoading that will change to false after locale is loaded
   * @example
   * ```typescript
   * const { isLoading } = loadModuleLocale('global')
   * ```
   */
  const loadModuleLocale = (
    moduleName: string
  ): { isLoading: Ref<boolean> } => {
    const global = i18n.value?.global
    const isLoading = ref(true)

    // ? Contain async / await calls while keeping loadLocale function synchronous
    ;(async () => {
      if (!global) {
        return
      }

      try {
        const locale = unref(global.locale)

        if (isModuleAlreadyLoaded(unref(global.messages), locale, moduleName)) {
          return
        }

        global.mergeLocaleMessage(locale, {
          [moduleName]: await loadJson(locale, moduleName),
        })
      } catch (error) {
        appsignal.send(error as Error, { locale: global.locale })
      } finally {
        isLoading.value = false
      }
    })()

    return {
      isLoading,
    }
  }

  return {
    loadModuleLocale,
  }
}
